import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faTwitter, faGithub, faReddit, faTelegram, faDiscord, faMedium, faInstagram, faPinterest, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { Images } from '../../assets/asset';
import { ENV } from '../../config/config'
import { useSelector, useDispatch } from 'react-redux';
import { getSettings, beforeSettings } from './footer.action';
import { getCategories, beforeCategory } from '../categories/categories.action';
import { getContentPages, beforeContentPages } from '../content-management/cms.actions';
import './footer.css';
const { gamification, myntistAiBaseUrl } = ENV

const MyntistFooter = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    const dispatch = useDispatch()
    const [settings, setSettings] = useState(null);
    const [categories, setCategories] = useState([])
    const [totalCategories, setTotalCategories] = useState(0)
    const [contentPages, setContentPages] = useState()
    const settingsRes = useSelector((state) => state.settings)
    const categoriesRes = useSelector((state) => state.category)
    const contentRes = useSelector((state) => state.cms)
    const userAddress = ENV.getUserKeys('address')?.address
    const scrollToCategories = searchParams.get('sc') || null

    useEffect(() => {
        dispatch(getSettings())
        dispatch(getCategories())
        dispatch(getContentPages())
    }, [])

    useEffect(() => {
        if (settingsRes.settingsAuth) {
            const { settings } = settingsRes
            dispatch(beforeSettings())
            setSettings(settings)
        }
    }, [settingsRes.settingsAuth])

    useEffect(() => {
        if (categoriesRes.getAuth) {
            setCategories(categoriesRes.categories?.slice(0, 3))
            setTotalCategories(categoriesRes.categories?.length)
            dispatch(beforeCategory())
        }
    }, [categoriesRes.getAuth])

    useEffect(() => {
        if (contentRes.contentPagesResAuth) {
            setContentPages(contentRes.contentPages.contentPages)
            dispatch(beforeContentPages())
        }
    }, [contentRes.contentPagesResAuth])

    const scrollToAllCategories = () => {
        const path = pathname === '/' ? '' : '/?sc=1'
        if (path)
            return navigate(path)
        else {
            const section = document.querySelector('.browse-by-category');
            if (section)
                section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <>
            <footer id="myntist-footer" className="position-relative">
                <div className="top-footer">
                    <Container fluid>
                        <Row>
                            <Col lg={4}>
                                <strong className="footer-logo mb-4 d-inline-block align-top">
                                    <Link className="d-inline-block align-top" to="/">
                                        <img width="170" height="50" className='img-fluid' src={Images.myntistLogo} alt="Site Logo" />
                                    </Link>
                                </strong>
                                <div className="about-hex text-white">
                                    <p>{settings && settings.desc ? settings.desc : ''}</p>
                                </div>
                                <div className="footer-widget">
                                    <h3 className="fw-bold text-white mb-4">Join the Community</h3>
                                    {
                                        settings &&
                                        <ul className="list-unstyled social-links d-flex">
                                            {
                                                settings.facebook ?
                                                    <li className="facebook">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.facebook} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.pinterest ?
                                                    <li className="pinterest">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.pinterest} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faPinterest} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.tiktok ?
                                                    <li className="tiktok">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.tiktok} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTiktok} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.medium ?
                                                    <li className="medium">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.medium} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faMedium} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.linkedIn ?
                                                    <li className="linkedin">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.linkedIn} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.instagram ?
                                                    <li className="instagram">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.instagram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.youtube ?
                                                    <li className="youtube">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.youtube} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.twitter ?
                                                    <li className="twitter">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.twitter} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.discord ?
                                                    <li className="discord">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.discord} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDiscord} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.reddit ?
                                                    <li className="reddit">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.reddit} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faReddit} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.github ?
                                                    <li className="github">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.github} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.telegram ?
                                                    <li className="telegram">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.telegram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTelegram} /></a>
                                                    </li> : null
                                            }
                                        </ul>
                                    }
                                </div>
                                <div className="web-owner-link">
                                    <h3 className="fw-bold text-white mb-4">Explore other Platforms</h3>
                                    <a target="_blank" rel="noreferrer" href={userAddress ? `${gamification.domainUrl}/dashboard?address=${userAddress}` : gamification.domainUrl} className="d-inline-flex align-items-center">
                                        <span className="gaming-img"><img className="img-fluid" src={Images.gamingIcon} alt="gamification" /> </span>
                                        <span className="gaming-head ms-2">Gamification</span>
                                    </a>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <Row>
                                    <Col sm={6} md={4} xl={3}>
                                        <div className="footer-nav-holder">
                                            <h3 className="fw-bold text-white">Marketplace</h3>
                                            <ul className="list-unstyled footer-nav">
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/explore-all' ? 'active' : ''} `} to="/explore-all">All NFTs</Link></li>
                                                {
                                                    categories && categories.length ?
                                                        <>
                                                            {
                                                                categories.map((category, index) => {
                                                                    return <li key={index}><Link className={`position-relative transition ${pathname?.toLowerCase() === `/category/${category.slug}` ? 'active' : ''}`} to={`/category/${category.slug}`}>{category.name}</Link></li>
                                                                })
                                                            }
                                                            {
                                                                totalCategories > 3 &&
                                                                <li>
                                                                    <span className='cyan-color cursor-pointer' onClick={() => scrollToAllCategories()}>
                                                                        View All
                                                                    </span>
                                                                </li>
                                                            }
                                                        </>
                                                        : null
                                                }
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} xl={3}>
                                        <div className="footer-nav-holder">
                                            <h3 className="fw-bold text-white">My Account</h3>
                                            <ul className="list-unstyled footer-nav">
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/profile' ? 'active' : ''} `} to='/profile' >Profile</Link></li>
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/my-favourites' ? 'active' : ''} `} to='/my-favourites' >Favourites</Link></li>
                                                {/* <li><Link className="position-relative transition" to={encryptedUser && encryptedUser !== null ? '/my-watchlist' : '/login/referrer=my-watchlist'} >Watchlist</Link></li> */}
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/my-collections' ? 'active' : ''} `} to='/my-collections'>My Collections</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} xl={3}>
                                        <div className="footer-nav-holder">
                                            <h3 className="fw-bold text-white">Resources</h3>
                                            <ul className="list-unstyled footer-nav">
                                                <li><Link className="position-relative transition" to='/faq'>Help Center</Link></li>
                                                <li><Link className="position-relative transition" target="_blank" to={userAddress ? myntistAiBaseUrl + "login?encuse=" + btoa(localStorage.getItem('encuse')) : ''} >Myntist Ai</Link></li>
                                                {
                                                    contentPages && contentPages.length ?
                                                        contentPages.map((contentPage, index) => {
                                                            return <li key={index} ><Link className={`position-relative transition ${pathname?.toLowerCase() === `/cms/${contentPage.slug}` ? 'active' : ''} `} to={`/cms/${contentPage.slug}`} >{contentPage.title}</Link></li>
                                                        }) : null
                                                }
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} xl={3}>
                                        <div className="footer-nav-holder">
                                            <h3 className="fw-bold text-white">Company</h3>
                                            <ul className="list-unstyled footer-nav">
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/contact' ? 'active' : ''} `} to='/contact'>Contact Us</Link></li>
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/privacy-and-terms' ? 'active' : ''} `} to='/privacy-and-terms'>Privacy Policy</Link></li>
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/how-it-works' ? 'active' : ''} `} to='/how-it-works'>How It Works</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} xl={3}>
                                        <div className="footer-nav-holder">
                                            <h3 className="fw-bold text-white">Get Started</h3>
                                            <ul className="list-unstyled footer-nav">
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/integration' ? 'active' : ''} `} to='/integration'>Integration</Link></li>
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/launchpad' ? 'active' : ''} `} to='/launchpad'>Launchpad</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} xl={3}>
                                        <div className="footer-nav-holder">
                                            <h3 className="fw-bold text-white">Stats</h3>
                                            <ul className="list-unstyled footer-nav">
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/rankings' ? 'active' : ''} `} to='/rankings'>Rankings</Link></li>
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/activity' ? 'active' : ''} `} to='/activity'>Activity</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} xl={3}>
                                        <div className="footer-nav-holder">
                                            <h3 className="fw-bold text-white">Explore</h3>
                                            <ul className="list-unstyled footer-nav">
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/authors' ? 'active' : ''} `} to='/authors'>Authors</Link></li>
                                                <li><Link className={`position-relative transition ${pathname?.toLowerCase() === '/gift-cards' ? 'active' : ''} `} to='/gift-cards'>Gift Cards</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="bottom-footer position-relative">
                    <Container>
                        <div className="copyright-text text-center text-white">
                            <p className="mb-0">All Rights Reserved by Myntist Company</p>
                        </div>
                    </Container>
                </div>
            </footer >
        </>
    )
}

export default MyntistFooter