import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, ListGroup } from 'react-bootstrap';
import { Images } from '../../assets/asset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faBars, faRightToBracket, faBell, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap';
import { ENV } from '../../config/config';
import { searchNft, beforeNFT } from '../nfts/nfts.action';
import './header.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Modal } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment'
import { beforeUserNotifications, getUserNotifications, editSeenStatus, beforeNotificationsStatus, beforeCounter } from '../notifications/notifications.actions';

const { gamification, myntistAiBaseUrl } = ENV

const MyntistHeader = () => {
    const { pathname } = useLocation();
    const { deactivate } = useWeb3React();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [input, setInput] = useState(false);
    const [dropCheck, setDropCheck] = useState(true)
    const [listCheck, setListCheck] = useState(false)
    const [navCheck, setNavCheck] = useState(0) // 0 by default , 1 if bar icon is clicked , any other for closing
    const [spinnerCheck, setSpinnerCheck] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [nftData, setNftData] = useState(null);
    const [collectionData, setCollectionData] = useState(null)
    const [counter, setCounter] = useState()
    const [page, setPage] = useState(1)
    const [moreCheck, setMoreCheck] = useState(true)
    const [userNotificationsList, setUserNotifications] = useState([])
    const [notificationsModal, setNotificationsModal] = useState(false)
    const [showSmallLoader, setSmallLoader] = useState(false)
    const [notificationsSeen, setSeen] = useState(false)

    const userId = ENV.getUserKeys('_id')?._id
    const userAddress = ENV.getUserKeys('address')?.address

    const nftRes = useSelector((state) => state.nft)
    const notificationsRes = useSelector((state) => state.notifications)
    const previousPage = useRef(window.location.pathname)
    let screenWidth = useRef(0)

    useEffect(() => {
        if (userId) {
            const filter = {
                userId,
                hasSeen: 2,
                all: true
            }

            let qs = ENV.objectToQueryString(filter)
            dispatch(getUserNotifications(qs))
        }
    }, [])

    useEffect(() => {
        if (notificationsModal) {
            const filter = {
                userId,
            }

            let qs = ENV.objectToQueryString(filter)
            dispatch(getUserNotifications(qs))
        }

    }, [notificationsModal])

    useEffect(() => {
        if (notificationsRes.userNotificationsAuth) {
            const { userNotifications, total } = notificationsRes.userNotifications
            if (total) {
                setCounter(total)
            }
            if (userNotifications) {
                setSmallLoader(false)
                if (userNotifications.length) {
                    setUserNotifications([...userNotificationsList, ...userNotifications])
                }
                else {
                    setMoreCheck(false)
                }
            }
            dispatch(beforeUserNotifications())
        }
    }, [notificationsRes.userNotificationsAuth])

    useEffect(() => {
        if (userNotificationsList && userNotificationsList.length) {
            let nCounter = 0
            userNotificationsList.map((n) => {
                if (!n.hasSeen)
                    nCounter++
            })
            if (!counter && nCounter > 0)
                setCounter(nCounter)
        }
    }, [userNotificationsList])

    useEffect(() => {
        if (notificationsRes.editNotificationsStatusAuth) {
            const { editNotificationsStatus } = notificationsRes
            if (editNotificationsStatus.success) {
                setSeen(true)
                setCounter(null)
                dispatch(beforeCounter())
                localStorage.setItem('notificationsCounter', 0)
            }
            dispatch(beforeNotificationsStatus())
        }
    }, [notificationsRes.editNotificationsStatusAuth])

    useEffect(() => {
        if (notificationsModal)
            setNotificationsModal(false)

    }, [window.location.pathname])

    const fetchData = () => {
        let qs = ENV.objectToQueryString({ page, userId: ENV.getUserKeys()?._id })
        dispatch(getUserNotifications(qs))
        setPage(page + 1)
    }

    window.addEventListener("resize", function (event) {
        let width = document.body.clientWidth
        screenWidth.current = width
        if (screenWidth.current >= 980)
            setNavCheck(0)
    })

    useEffect(() => {
        document.onclick = function closeSearch(e) {
            const searchIcon = document.getElementById('search-icon-header');
            const searchBar = document.getElementById('site-search');
            const list = document.getElementById('headerSearchList');
            const navbar = document.getElementById('nav-icon')

            if (list) {
                if (!list.contains(e.target)) {
                    setListCheck(false)
                }
            }

            if (searchIcon && searchBar) {
                if (!searchIcon.contains(e.target) && !searchBar.contains(e.target)) {
                    setSearchInput('')
                    setInput(false)
                }
            }

            if (navbar) {
                if (!navbar.contains(e.target) && screenWidth.current && screenWidth.current < 980) {
                    setNavCheck(2)
                }
            }
        }
    }, [document.onClick])

    useEffect(() => {
        if (screenWidth.current && screenWidth.current < 980 && previousPage.current !== window.location.pathname) {
            previousPage.current = window.location.pathname;
            setNavCheck(2)
        }
    }, [window.location.pathname])

    const logoutHandler = () => {
        localStorage.clear()
        deactivate()
        setDropCheck(false)
        navigate('/')
    }

    const formatAddress = (address) => {
        return address ? address.substr(0, 6) + '...' + address.substr(-4) : null;
    }

    const searchHandler = () => {
        if (navCheck === 1)
            setNavCheck(2)

        setInput(!input)
        if (searchInput) {
            const qs = ENV.objectToQueryString({ name: window.btoa(searchInput) })
            dispatch(searchNft(null, qs))
            setSpinnerCheck(true)
        }
    }

    useEffect(() => {
        if (nftRes.searchAuth) {
            const { nfts, collections } = nftRes.searchData
            if (nfts.length) {
                setNftData(nfts)
                setCollectionData(collections)
                dispatch(beforeNFT())
                setSpinnerCheck(false)
                setListCheck(true)
            }
            else {
                setSpinnerCheck(false)
                setListCheck(false)
                dispatch(beforeNFT())
                setSearchInput('')
                toast.error('No items found for this search!', { toastId: 'nft-not-found' })
            }
        }
    }, [nftRes.searchAuth])

    useEffect(() => {
        if (notificationsRes) {
            let value = notificationsRes.notificationsCounter
            value = value ? value : null
            setCounter(value)
            setSeen(false)
            // if (notificationsRes.notificationsCounter === 0)
            //     setSeen(true)
        }

    }, [notificationsRes.notificationsCounter])

    const rendList = () => {
        if (listCheck) {
            return (
                <ListGroup className={!listCheck ? `d-none` : ''} id="headerSearchList">
                    {
                        collectionData && collectionData.length ?
                            <ListGroup.Item active>Collections</ListGroup.Item>
                            : ''
                    }
                    {
                        collectionData && collectionData.length ?
                            collectionData.map((item, index) => {
                                return (
                                    <Link to={`/collection/${item.url}`} key={index}><ListGroup.Item key={index}>{item.name}</ListGroup.Item></Link>
                                )
                            }) : ''
                    }
                    {
                        nftData && nftData.length && listCheck ?
                            <ListGroup.Item active>Items</ListGroup.Item>
                            : ''
                    }
                    {
                        nftData && nftData.length && listCheck ? nftData.map((item, index) => {
                            return (
                                <Link to={`/item-details/${window.btoa(item._id)}`} key={index}><ListGroup.Item onClick={() => setListCheck(false)} key={index}>{item.name}</ListGroup.Item></Link>
                            )
                        }) : ''
                    }
                </ListGroup>
            )
        }
    }

    const getNotificationsText = (notification) => {
        if (notification.type === 1)
            return (
                <>
                    <Link to={`/author/${notification.notificationFromUser._id}`}>{notification.notificationFromUser.name}</Link>
                    {` has given you a `}
                    <Link to={`/author/${userId}?key=feedbacks`}>Feedback</Link>
                </>
            )
        else if (notification.type === 2)
            return (
                <>
                    <Link to={`/author/${notification.notificationFromUser._id}`}>{notification.notificationFromUser.name}</Link>
                    {` has placed a bid on `}
                    <Link to={`/item-details/${window.btoa(notification.nft._id)}`}>{notification.nft.name}</Link>
                </>
            )
        else if (notification.type === 3)
            return (
                <>
                    <Link to={`/author/${notification.notificationFromUser._id}`}>{notification.notificationFromUser.name}</Link>
                    {` has placed an offer on `}
                    <Link to={`/item-details/${window.btoa(notification.nft._id)}`}>{notification.nft.name}</Link>
                </>
            )
        else if (notification.type === 4)
            return (
                <>
                    <Link to={`/author/${notification.notificationFromUser._id}`}>{notification.notificationFromUser.name}</Link>
                    {` has accepted your bid on `}
                    <Link to={`/item-details/${window.btoa(notification.nft._id)}`}>{notification.nft.name}</Link>
                </>
            )
        else if (notification.type === 5)
            return (
                <>
                    <Link to={`/author/${notification.notificationFromUser._id}`}>{notification.notificationFromUser.name}</Link>
                    {` has accepted your offer on `}
                    <Link to={`/item-details/${window.btoa(notification.nft._id)}`}>{notification.nft.name}</Link>
                </>
            )
        else if (notification.type === 6)
            return (
                <>
                    <Link to={`/author/${notification.notificationFromUser._id}`}>{notification.notificationFromUser.name}</Link>
                    {` has bought NFT `}
                    <Link to={`/item-details/${window.btoa(notification.nft._id)}`}>{notification.nft.name}</Link>
                </>
            )
        else if (notification.type === 7)
            return (
                <>
                    {` ${notification.notificationFromAdmin.name} has sent you a message on your `}
                    <Link to={`/nft-reports/${notification.reportedNft._id}`}>{`Report `}</Link>
                    {`against NFT `}
                    <Link to={`/item-details/${window.btoa(notification.nft._id)}`}>{notification.nft.name}</Link>
                </>
            )

    }

    const notificationsModalHandler = () => {
        setNotificationsModal(!notificationsModal);
        setSmallLoader(true)
        setUserNotifications([])
        setPage(1)
        dispatch(editSeenStatus(userId))
    }

    return (
        <>
            <header id="myntist-header" className="header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <strong className="logo d-inline-block align-top">
                        <Link to="/" className="d-inline-block align-top">
                            <LazyLoadImage className="img-fluid" width="170" height="50" /* effect = 'blur' */ src={Images.myntistLogo} alt="Site Logo" />
                        </Link>
                    </strong>
                </div>
                <div className="d-flex justify-content-end">
                    <Navbar expand="lg" className="pos-stat justify-content-end p-0 myntist-navbar" id='navbar-header' >
                        <Navbar.Toggle aria-controls="basic-navbar-nav">
                            <FontAwesomeIcon icon={faBars} className="" id="nav-icon" onClick={() => setNavCheck(1)} />
                        </Navbar.Toggle>
                        {
                            (navCheck === 0 || navCheck === 1) &&
                            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                                <ul className={`navbar-nav align-items-lg-center ${input ? 'd-none' : 'd-flex'}`} >
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/explore-all' ? 'active' : ''}`} ><Link className="nav-link transition position-relative" to="/explore-all">Marketplace</Link></li>
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/collections' ? 'active' : ''}`}><Link className="nav-link transition position-relative" to="/collections">Collections</Link></li>
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/auctions' ? 'active' : ''}`}><Link className="nav-link transition position-relative" to="/auctions">Live Auctions</Link></li>
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/rankings' ? 'active' : ''}`} ><Link className="nav-link transition position-relative" to="/rankings">Rankings</Link></li>
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/activity' ? 'active' : ''}`}><Link className="nav-link transition position-relative" to="/activity">Activity</Link></li>
                                    <li className={`nav-item ${pathname?.toLowerCase() === '/launchpad' ? 'active' : ''}`}><Link className="nav-link transition position-relative" to='/launchpad'>Launch Pad</Link></li>
                                </ul>
                            </Navbar.Collapse>
                        }
                        <span className="search-span">
                            {
                                spinnerCheck ?
                                    <div className='search-submit-loader mt-2'>
                                        <span className="mynt-loader">
                                            <img className='img-fluid' alt="myntist mini loader" src={Images.favIcon} />
                                        </span>
                                    </div>
                                    :
                                    <form onSubmit={() => searchHandler()} className="search-form transition">
                                        <input type="text" id="site-search" placeholder="Enter to Search" className={`transition  ${input ? 'input-width' : 'input-no-width'}`} aria-label="Search through site content" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                        <span id="search-icon-header" className={`search-icon-inner  ${input ? 'active' : ''} `} onClick={() => searchHandler()}>
                                            <LazyLoadImage className="img-fluid white-logo" width="13" height="13" /* effect = 'blur' */ src={Images.whiteSearch} alt="White Search" />
                                            <LazyLoadImage className="img-fluid blue-logo" width="13" height="13" /* effect = 'blur' */ src={Images.blurDarkSearch} alt="Blue Search" />
                                            <LazyLoadImage className="img-fluid cyan-logo" width="13" height="13" /* effect = 'blur' */ src={Images.cyanSearch} alt="Cyan Search" />
                                        </span>
                                    </form>
                            }
                        </span>
                        {
                            userId &&
                            <div className='notification-span' onClick={notificationsModalHandler}>
                                <FontAwesomeIcon icon={faBell} className="wallet-icon" /><span style={{ fontWeight: !notificationsSeen ? 'bold' : '' }} className={counter ? "wallet-address" : ""}>{counter}</span>
                            </div>
                        }
                        {(localStorage.getItem('encuse') && localStorage.getItem('encuse') !== 'null' && localStorage.getItem('encuse') !== null) && dropCheck
                            ?
                            <span className={`nav-item ${pathname?.toLowerCase() === '/login' ? 'active' : ''} button-span`}>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="btn ml-lg-auto myntist-login-button d-flex align-items-center">
                                        <FontAwesomeIcon icon={faWallet} className="wallet-icon me-2" onClick={() => setNavCheck(2)} /><span className="wallet-address">{formatAddress(localStorage.getItem('connectedAddress'))}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/profile' ? 'active-status' : ''} onClick={() => { navigate('/profile') }}>My Profile</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/create' ? 'active-status' : ''} onClick={() => { navigate('/create') }}>Create NFT</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/my-collections' ? 'active-status' : ''} onClick={() => { navigate('/my-collections') }}>My Collections</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === `/author/${userId}` ? 'active-status' : ''} onClick={() => { navigate(`/author/${userId}`) }}>My Items</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/earnings' ? 'active-status' : ''} onClick={() => { navigate('/earnings') }}>My Earnings</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/my-favourites' ? 'active-status' : ''} onClick={() => { navigate('/my-favourites') }}>My Favourites</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/trading-history' ? 'active-status' : ''} onClick={() => { navigate('/trading-history') }}>Trading History</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/nft-reports' ? 'active-status' : ''} onClick={() => { navigate('/nft-reports') }}>NFTs Reporting</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/bulk-upload-logs' ? 'active-status' : ''} onClick={() => { navigate('/bulk-upload-logs') }}>Bulk Upload Logs</Dropdown.Item>
                                        <Dropdown.Item target="_blank" href={userAddress ? `${gamification.domainUrl}/levels` : gamification.domainUrl}>Rewards & Points</Dropdown.Item>
                                        <Dropdown.Item target="_blank" href={userAddress ? myntistAiBaseUrl + "login?encuse=" + btoa(localStorage.getItem('encuse')) : ''}>Myntist AI</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/gift-cards' ? 'active-status' : ''} onClick={() => { navigate('/gift-cards') }}>Gift Cards</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/feedbacks' ? 'active-status' : ''} onClick={() => { navigate('/feedbacks') }}>Feedbacks</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/contact' ? 'active-status' : ''} onClick={() => { navigate('/contact') }}>Account Support</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" onClick={logoutHandler}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                            :
                            <span className="button-span">
                                <Link className="myntist-login-button" to="/login">
                                    <span className="outer">
                                        <span className="inner">Login</span>
                                    </span>
                                </Link>
                                <Link className="myntist-login-button-mobile" to="/login">
                                    <span className="outer">
                                        <span className="inner"><FontAwesomeIcon icon={faRightToBracket} className="" /></span>
                                    </span>
                                </Link>
                            </span>
                        }
                        {
                            rendList()
                        }
                    </Navbar>
                </div>

            </header>
            {
                notificationsModal &&
                <div className="modal-wrapper">
                    <Modal
                        centered
                        size="md"
                        className="report-modal owner-modal"
                        show={notificationsModal}
                        onHide={() => setNotificationsModal(!notificationsModal)}
                    >
                        <Modal.Header className="text-center  modal-title-wrapper contest-modal">
                            <Modal.Title>
                                Notifications
                                <span className="cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={() => setNotificationsModal(!notificationsModal)} /></span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="row pt-2 pb-0">
                            <div className="container">
                                <div className="row">
                                    {
                                        <div className="col-12">
                                            {
                                                showSmallLoader ?
                                                    <span className="gift-sumbit-loader">
                                                        <div className="mynt-loader">
                                                            <img className='img-fluid' alt="apply-voucher-loader" src={Images.favIcon} />
                                                        </div>
                                                    </span>
                                                    :
                                                    <ul className='list-unstyled'>
                                                        <InfiniteScroll
                                                            dataLength={userNotificationsList.length} //This is important field to render the next data
                                                            next={fetchData}
                                                            hasMore={moreCheck}
                                                            loader={<h4>Loading...</h4>}
                                                            className="notifications-scroll"
                                                        >
                                                            {

                                                                userNotificationsList?.length > 0 ?

                                                                    userNotificationsList?.map((notification, idx) => {
                                                                        return (

                                                                            <li key={`notification-${idx}`}>
                                                                                { }
                                                                                <div className="owner-meta">
                                                                                    <div className='owner-data d-flex align-items-center'>
                                                                                        <Link className="owner d-flex align-items-center ms-2" to={`/author/${notification.notificationFromAdmin._id || notification.notificationFromAdmin._id}`} >
                                                                                            <img src={notification.profileImage} className="img-fluid" alt={`user-photo-${idx}`} />
                                                                                        </Link>
                                                                                        <div className='row rest-owner'>
                                                                                            <div className='col-12'>
                                                                                                <span style={{ fontWeight: notification.hasSeen ? '' : 'bold' }}>
                                                                                                    {getNotificationsText(notification)}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='col-12'>
                                                                                                <span className='date-time'>{moment(notification.createdAt).format('DD/MM/YYYY HH:mm')}</span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <li className='no-notifications-section d-flex justify-content-center align-items-center'>No Notifications Found</li>
                                                            }

                                                        </InfiniteScroll>
                                                    </ul>

                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <button
                                className="outline-button"
                                onClick={() => setNotificationsModal(!notificationsModal)}
                            >
                                Close
                            </button>
                        </Modal.Footer> */}
                    </Modal>
                </div>
            }
        </>
    )
}

export default MyntistHeader