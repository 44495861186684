import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ENV } from '../../config/config'
const { gamification } = ENV

const ContestPopup = (props) => {
    const [contestList, setContestList] = useState([])
    const [isOpen, setOpen] = useState(false)
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        pauseOnFocus: false,
    }
    useEffect(() => {
        if (props.contestList && props.contestList.length) {
            setContestList(props.contestList)
            setOpen(true)
        }
    }, [props.contestList])

    return (
        <div>
            <Modal
                centered
                size="md"
                className="offer-modal contest-modal"
                show={isOpen}
                onHide={() => setOpen(false)}
            >
                <Modal.Header className="text-center modal-title-wrapper position-relative contest-modal">
                    <Modal.Title>
                        Contests
                        <span className="cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={() => setOpen(false)} /></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="row pt-2 pb-0">
                    <Slider {...settings}>
                        {
                            contestList && contestList.length &&
                            contestList.map((contest, index) => {
                                return (
                                    <div key={index} className="col-12 d-flex justify-content-center">
                                        <div>
                                            <a href={`${gamification.domainUrl}/uploadContest?contestId=${contest?._id}`} className="contest-name" target="_blank" rel="noreferrer">{contest.name}</a>
                                            <div>{`Points : ${contest.points}`}</div>
                                            <p dangerouslySetInnerHTML={{ __html: contest.description }} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ContestPopup