import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { setPoints, createStats, getContestList, beforeContests } from './gamification.action'
import ContestPopup from '../popup/contestPopup'
import { ENV } from '../../config/config'
const { getUserKeys } = ENV

const Gamification = (props) => {
    const [contestList, setContestList] = useState([])

    useEffect(() => {
        if (!props.gamification.points) {
            props.setPoints() // set points for gamification
        }
    }, [])

    useEffect(() => {
        if (window.location.pathname === '/') {
            props.getContestList()
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (props.gamification.contestsAuth) {
            setContestList(props.gamification.contests)
            props.beforeContests()
        }
    }, [props.gamification.contestsAuth])

    useEffect(() => {
        if (props.gamification.points?.events?.length && props.eventType && props.eventType !== '') {
            if (props.userAddress)
                createGStats(props.eventType, props.userAddress)
            else
                createGStats(props.eventType)
        }
    }, [props.gamification.points, props.eventType])

    const createGStats = (eventT = '', userAddress = null) => {
        const nftPlatform = 'nft'.toLowerCase()
        const userId = userAddress ? userAddress : getUserKeys('address')?.address // user wallet address
        const event = props?.gamification?.points.events.find((elem) => elem?.eventType === eventT && elem?.platform?.toLowerCase() === nftPlatform)
        if (!userId || !event)
            return false

        const { eventType, pointType, platform, name, isFundraiser, fundRaising } = event
        const payload = {
            userId, eventType, pointType, platform, eventName: name
        }
        const isInvestor = getUserKeys('isInvestor')?.isInvestor // flag to know either if user was investor or not
        if (isFundraiser && isInvestor)
            payload.fundRaising = fundRaising

        props.createStats(payload)
    }

    return (
        <>
            {
                contestList &&
                //  contestList.length &&
                <ContestPopup contestList={contestList} />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    gamification: state.gamification
})

export default connect(mapStateToProps, { setPoints, createStats, getContestList, beforeContests }, null)(Gamification)