import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/scroll-to-top/scroll-to-top'
import { Provider } from 'react-redux';
import store from './store';
import { toast, ToastContainer } from 'react-toastify';
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  newestOnTop: true,
  autoClose: 3000
});

const getLibrary = async (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
};

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer />
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Web3ReactProvider>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById("root")
);

serviceWorker.unregister();
