import { useEffect } from 'react'
import { connect } from "react-redux";
import { beforeApp, getTokensConvertedPrices } from './App.action';
import { beforeWallet } from './components/wallet/wallet.action';
import { useNavigate } from 'react-router-dom';
import { ENV } from './config/config';
import { useWeb3React } from "@web3-react/core";
import { connectWallet } from './utils/web3';
import { accountsChangedHandler } from './utils/web3';
import { io } from 'socket.io-client';
import { setNotificationCounter } from './components/notifications/notifications.actions';

const { connectors, notifications, domainUrl } = ENV

const Getter = (props) => {
    const navigate = useNavigate();
    const { activate, deactivate } = useWeb3React();
    const userId = ENV.getUserKeys()?._id
    useEffect(() => {
        if (props.notifications.notificationsCounter) {
            let notificationCounter = props.notifications.notificationsCounter
            localStorage.setItem('notificationsCounter', notificationCounter || 0)
        }
    }, [props.notifications.notificationsCounter])

    useEffect(() => {
        let socket = io(domainUrl);
        socket.on('connect', function () {
            notificationsHandler(socket)
        });
        return () => socket.close();
    }, [])


    useEffect(() => {
        if (props.wallet.redirectW) {
            props.beforeWallet()
            navigate('/login')
        }
    }, [props.wallet.redirectW])

    useEffect(() => {
        props.getTokensConvertedPrices()
    }, [])

    useEffect(() => {
        accountsChangedHandler()
    }, [window.location.pathname])

    // activate connection upon refresh
    useEffect(async () => {
        const providerInstance = window.localStorage.getItem("provider");
        const connectedAddress = window.localStorage.getItem("connectedAddress");
        if (providerInstance) {
            const connector = await connectors[providerInstance]
            const provider = await connector.getProvider()
            if (!provider && connectedAddress) {
                const account = await connectWallet(false, activate)

                // redirect to login if connection is disabled from mobile app
                if (!account) {
                    deactivate()
                    localStorage.clear()
                    navigate('/login')
                }

            }
        }
    }, [])

    useEffect(() => {
        // when an error is received
        if (props.error) {
            // if user is not found, clear storage and redirect to connect wallet screen
            if (props.error.user404) {
                localStorage.clear()
                navigate('/login')
            }
        }
    }, [props.error])
    const notificationsHandler = (socket) => {

        if (!socket.connected) {
            socket = io(domainUrl, { 'forceNew': true });
            socket.on('connect', function () {
                notificationsHandler(socket)
            });

        }

        let notificationsData = [...notifications]
        notificationsData.map((n) => {
            socket.on(`${n.name}-${userId}`, (data) => {
                props.setNotificationCounter()
            })
        })
    }

    return (
        <>
        </>
    )
}

const mapStateToProps = (state) => ({
    app: state.app,
    wallet: state.wallet,
    error: state.error,
    notifications: state.notifications
});

export default connect(mapStateToProps, { beforeApp, getTokensConvertedPrices, beforeWallet, setNotificationCounter })(Getter);
