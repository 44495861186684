import { BEFORE_APP, SET_RATES } from './redux/types';

const initialState = {
    rate: null, // BNB to USD rate
    rateAuth: false,
    wbnbRate: null,
    wbnbRateAuth: false,
    myntRate: null,
    myntRateAuth: false,
    wbnbToBnbRate: null,
    wbnbToBnbRateAuth: false,
    myntToBnbRate: null,
    myntToBnbRateAuth: false,
    bnbToWbnbRate: null,
    bnbToWbnbAuth: false,

    ethToUsdRate: null,
    ethToUsdRateAuth: false,
    ethToWethRate: null,
    ethToWethRateAuth: false,
    myntToEthRate: null,
    myntToEthRateAuth: false,
    wethToEthRate: null,
    wethToEthRateAuth: false,
    wethToUsdRate: null,
    wethToUsdRateAuth: false,
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case SET_RATES:
            const {
                BNBtoUSD,
                WBNBtoUSD,
                MYNTtoUSD,
                WBNBtoBNB,
                MYNTtoBNB,
                BNBtoWBNB,

                ETHtoUSD,
                ETHtoWETH,
                MYNTtoETH,
                WETHtoETH,
                WETHtoUSD

            } = action.payload
            return {
                ...state,

                rate: BNBtoUSD,
                rateAuth: true,

                wbnbRate: WBNBtoUSD,
                wbnbRateAuth: true,

                myntRate: MYNTtoUSD,
                myntRateAuth: true,

                wbnbToBnbRate: WBNBtoBNB,
                wbnbToBnbRateAuth: true,

                myntToBnbRate: MYNTtoBNB,
                myntToBnbRateAuth: true,

                bnbToWbnbRate: BNBtoWBNB,
                bnbToWbnbAuth: true,

                ethToUsdRate: ETHtoUSD,
                ethToUsdRateAuth: true,

                ethToWethRate: ETHtoWETH,
                ethToWethRateAuth: true,

                myntToEthRate: MYNTtoETH,
                myntToEthRateAuth: true,

                wethToEthRate: WETHtoETH,
                wethToEthRateAuth: true,

                wethToUsdRate: WETHtoUSD,
                wethToUsdRateAuth: true
            }
        case BEFORE_APP:
            return {
                ...state,
                rate: null,
                rateAuth: false,
                wbnbRate: null,
                wbnbRateAuth: false,
                myntRate: null,
                myntRateAuth: false,
                wbnbToBnbRate: null,
                wbnbToBnbRateAuth: false,
                myntToBnbRate: null,
                myntToBnbRateAuth: false,
                bnbToWbnbRate: null,
                bnbToWbnbAuth: false,

                ethToUsdRate: null,
                ethToUsdRateAuth: false,
                ethToWethRate: null,
                ethToWethRateAuth: false,
                myntToEthRate: null,
                myntToEthRateAuth: false,
                wethToEthRate: null,
                wethToEthRateAuth: false,
                wethToUsdRate: null,
                wethToUsdRateAuth: false
            }
        default:
            return {
                ...state
            }
    }
}