import { BEFORE_STAKING, GET_STAKINGS } from '../../redux/types'
// , UNSTAKING

const initialState = {
    staking: null,
    stakings: null,
    pagination: null,
    // unstakeAuth: false,
    getAuth: false
}

export default function stakingsRed(state = initialState, action) {
    switch (action.type) {
        case GET_STAKINGS:
            return {
                ...state,
                stakings: action.payload.stakings,
                pagination: action.payload.pagination,
                getAuth: true
            }
        // case UNSTAKING:
        //     return {
        //         ...state,
        //         staking: action.payload,
        //         unstakeAuth: true
        //     }
        case BEFORE_STAKING:
            return {
                ...state,
                staking: null,
                stakings: null,
                pagination: null,
                // unstakeAuth: false,
                getAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}