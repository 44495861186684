import React from 'react';

// layouts
import Layout1 from './layouts/layout1/layout1';
import Layout2 from './layouts/layout2/layout2';
import Layout4 from './layouts/layout4/layout4';

// components
const Template = React.lazy(() => import('./components/template/template'));
const ExploreAll = React.lazy(() => import('./components/explore/explore-all'));
const LiveAuctions = React.lazy(() => import('./components/auctions/live-auctions-list'));
const ItemDetails = React.lazy(() => import('./components/item-detail/item-detail'));
const Activity = React.lazy(() => import('./components/activity/activity'));
const Rankings = React.lazy(() => import('./components/rankings/rankings'));
const Earnings = React.lazy(() => import('./components/earnings/earnings'));
const HelpCenter = React.lazy(() => import('./components/help-center/help-center'));
const Authors = React.lazy(() => import('./components/authors-list/authors-list'));
const Author = React.lazy(() => import('./components/author/author'));
const Profile = React.lazy(() => import('./components/profile/profile'));
const CreateNFT = React.lazy(() => import('./components/create-nft/create-nft'));
const MyCollections = React.lazy(() => import('./components/collections/my-collections'));
const AllCollections = React.lazy(() => import('./components/collections/all-collections'));
const CreateCollection = React.lazy(() => import('./components/create-collection/create-collection'));
const EidtCollection = React.lazy(() => import('./components/create-collection/edit-collection'));
const CollectionDetails = React.lazy(() => import('./components/collections/collection-details'));
const Wallet = React.lazy(() => import('./components/wallet/wallet'));
const Contact = React.lazy(() => import('./components/contact/contact'));
const SellNFT = React.lazy(() => import('./components/nfts/sell-nft'));
const NotFound = React.lazy(() => import('./components/not-found/not-found'));
const HowItWorks = React.lazy(() => import('./components/how-it-works/how-it-works'));
const PrivacyTerms = React.lazy(() => import('./components/privacy-&-terms/privacy-&-terms'));
const Integration = React.lazy(() => import('./components/integration/integration'));
const Faq = React.lazy(() => import('./components/faq/faq'));
const Trading = React.lazy(() => import('./components/trading/trading'));
const ExploreCategory = React.lazy(() => import('./components/categories/explore-category'));
const NftReportings = React.lazy(() => import('./components/nftReportings/nftReportings'));
const ViewNftReport = React.lazy(() => import('./components/nftReportings/nftReport'));
const MyFavourites = React.lazy(() => import('./components/my-favourites/favourites'))
const CMS = React.lazy(() => import('./components/content-management/contentPages'))
const GiftCards = React.lazy(() => import('./components/giftCards/giftCards'));
const Launchpad = React.lazy(() => import('./components/myntist/launchpad/launchpad'));
const BulkUploadLogs = React.lazy(() => import('./components/myntist/bulk-upload/bulkUploadLogs'));
const Feedbacks = React.lazy(() => import('./components/feedbacks/feedbacks'))
const DiscordAuth = React.lazy(() => import('./components/profile/discord.auth'))

// routes
const routes = [
    { path: '/', access: true, exact: true, title: 'Template', layout: Layout4, component: Template },
    { path: '/launchpad', access: true, exact: true, title: 'Launchpad', layout: Layout2, component: Launchpad },
    { path: '/explore-all', access: true, exact: true, title: 'Explore', layout: Layout2, component: ExploreAll },
    { path: '/faq', access: true, exact: true, title: 'Faq', layout: Layout2, component: Faq },
    { path: '/auctions', access: true, exact: true, title: 'Live Auctions', layout: Layout2, component: LiveAuctions },
    { path: '/item-details/:item', access: true, exact: true, title: 'Item Details', layout: Layout2, component: ItemDetails },
    { path: '/item-details/:item/:referral', access: true, exact: true, title: 'Item Details', layout: Layout2, component: ItemDetails },
    { path: '/sell-item/:itemId', access: true, exact: true, title: 'Sell Item', layout: Layout2, component: SellNFT },
    { path: '/activity', access: true, exact: true, title: 'Activity', layout: Layout2, component: Activity },
    { path: '/rankings', access: true, exact: true, title: 'Rankings', layout: Layout2, component: Rankings },
    { path: '/gift-cards', access: true, exact: true, title: 'Gift Cards', layout: Layout2, component: GiftCards },
    { path: '/bulk-upload-logs', access: true, exact: true, title: 'Bulk Upload Logs', layout: Layout2, component: BulkUploadLogs },
    { path: '/earnings', access: true, exact: true, title: 'Earnings', layout: Layout2, component: Earnings },
    { path: '/trading-history', access: true, exact: true, title: 'Trading', layout: Layout2, component: Trading },
    { path: '/help-center', access: true, exact: true, title: 'Help Center', layout: Layout1, component: HelpCenter },
    { path: '/authors', access: true, exact: true, title: 'Authors', layout: Layout2, component: Authors },
    { path: '/author/:authorId', access: true, exact: true, title: 'Author Profile', layout: Layout1, component: Author },
    { path: '/profile', access: true, exact: true, title: 'Profile', layout: Layout2, component: Profile },
    { path: '/discord-auth', access: true, exact: true, title: 'Discord Authentication', layout: Layout1, component: DiscordAuth },
    { path: '/create', access: true, exact: true, title: 'Create', layout: Layout2, component: CreateNFT },
    { path: '/my-collections', access: true, exact: true, title: 'Collections', layout: Layout2, component: MyCollections },
    { path: '/collection/create', access: true, exact: true, title: 'Create Collection', layout: Layout2, component: CreateCollection },
    { path: '/collections', access: true, exact: true, title: 'Collections', layout: Layout2, component: AllCollections },
    { path: '/collection/edit/:id', access: true, exact: true, title: 'Create Collection', layout: Layout2, component: EidtCollection },
    { path: '/collection/:collectionId', access: true, exact: true, title: 'Collection Details', layout: Layout1, component: CollectionDetails },
    { path: '/nft-reports', access: true, exact: true, title: 'NFT Reportings', layout: Layout2, component: NftReportings },
    { path: '/nft-reports/:reportId', access: true, exact: true, title: 'NFT Report', layout: Layout2, component: ViewNftReport },
    { path: '/login/:referrer', access: true, exact: true, title: 'login', layout: Layout4, component: Wallet },
    { path: '/login', access: true, exact: true, title: 'login', layout: Layout4, component: Wallet },
    { path: '/contact', access: true, exact: true, title: 'Contact', layout: Layout2, component: Contact },
    { path: '/cms/:slug', access: true, exact: true, title: 'CMS', layout: Layout2, component: CMS },
    { path: '/how-it-works?', access: true, exact: true, title: 'How It Works', layout: Layout2, component: HowItWorks },
    { path: '/privacy-and-terms', access: true, exact: true, title: 'Privacy & Terms', layout: Layout2, component: PrivacyTerms },
    { path: '/integration', access: true, exact: true, title: 'Integration', layout: Layout1, component: Integration },
    { path: '/category/:slug', access: true, exact: true, title: 'Explore Category', layout: Layout1, component: ExploreCategory },
    { path: '/my-favourites', access: true, exact: true, title: 'My Favourites', layout: Layout2, component: MyFavourites },
    { path: '/feedbacks', access: true, exact: true, title: 'Feedbacks', layout: Layout2, component: Feedbacks },
    { path: '/*', access: true, exact: true, name: 'Not Found', layout: Layout2, component: NotFound }
];

export default routes;