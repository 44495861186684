import {
    GET_HEADINGS, GET_CONTENT, BEFORE_HOWITWORKS
} from '../../redux/types';

const initialState = {
    headingsRes: null,
    headingsAuth: false,

    contentRes: null,
    contentAuth: false,


}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HEADINGS:
            return {
                ...state,
                headingsRes: action.payload,
                headingsAuth: true,
            }
        case GET_CONTENT:
            return {
                ...state,
                contentRes: action.payload,
                contentAuth: true,
            }

        case BEFORE_HOWITWORKS:
            return {
                ...state,
                headingsRes: null,
                headingsAuth: false,
                contentRes: null,
                contentAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}