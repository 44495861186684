import { SET_COUNTER, BEFORE_COUNTER, USER_NOTIFICATIONS, BEFORE_NOTIFICATIONS, EDIT_NOTIFICATION_STATUS, BEFORE_NOTIFICATION_STATUS, GET_ERRORS } from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action'
import { toast } from 'react-toastify'
import { ENV } from './../../config/config'

export const beforeCounter = () => {
    return {
        type: BEFORE_COUNTER
    }
}

export const setNotificationCounter = () => {
    return {
        type: SET_COUNTER,
    }
}

export const beforeUserNotifications = () => {
    return {
        type: BEFORE_NOTIFICATIONS
    }
}

export const getUserNotifications = (qs = null) => dispatch => {
    dispatch(emptyError());
    toast.dismiss()
    let url = `${ENV.url}notifications/get`
    if (qs) 
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: USER_NOTIFICATIONS,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const editSeenStatus = (userId) => dispatch => {
    dispatch(emptyError());
    toast.dismiss()
    let url = `${ENV.url}notifications/edit/${userId}`

    fetch(url, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: EDIT_NOTIFICATION_STATUS,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const beforeNotificationsStatus = () => {
    return {
        type: BEFORE_NOTIFICATION_STATUS
    }
}


