import React, { useEffect } from 'react';
import './full-page-loader.css';
import { Images } from '../../assets/asset';



function FullPageLoader() {
    // useEffect(() => {
    //     AOS.init();
    // }, []);

    return (
        <React.Fragment>
            <div className="fullpage-loader-holder d-flex justify-content-center align-items-center">
                <div className='img-loader'>
                    <img className="img-fluid" src={Images.favIcon} alt="Full page loader" />
                </div> 
            </div>
        </React.Fragment>
    );
};

export default FullPageLoader;