import Web3 from "web3";
import { ethers } from "ethers";
import randomstring from "randomstring";
import { toast } from 'react-toastify'
import contractAbi from './abis/token.json';
import myntistAbi from './abis/myntist20.json'
import myntist721Abi from './abis/myntist721.json'
import myntist1155Abi from './abis/myntist1155.json'
import { ENV } from '../config/config';
import store from '../store'
import { axiosSyncPost } from './functions';
import { SET_WALLET_ERROR, REDIRECT_TO_WALLET } from '../redux/types';

const { nftContractAddress,
    myntContractAddress, requiredChainIds, blockChains, getChainDataByKey,
    connectors } = ENV

const Contract = require('web3-eth-contract');

const call = (method, params) => {
    return new Promise((resolve, reject) => {
        method(...params)
            .call()
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const send = (method, params, from, value = 0) => {
    return new Promise((resolve, reject) => {
        method(...params)
            .send({ from, value })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const methods = {
    call,
    send,
};

export const getWeb3 = () => {
    if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        return web3;
    }
    else {
        return false;
    }
}

export const getWeb3Provider = async () => {
    const providerInstance = await getProviderFromStorage()
    const provider = await providerInstance?.getProvider()

    if (!provider) {
        store.dispatch(redirectToWallet())
        return false
    }
    const web3Provider = await new Web3(provider);
    return web3Provider
}

export const getProviderFromStorage = async () => {
    let providerFromStorage = localStorage.getItem('provider')
    const providerInstance = await connectors[providerFromStorage]

    return providerInstance
}

export const isWalletLocked = async () => {
    try {
        if (localStorage.getItem('connectedAddress') || localStorage.getItem('encuse')) {
            const web3Provider = await getWeb3Provider()
            if (!web3Provider) {
                return null
            }
            const accounts = await web3Provider.eth.getAccounts();
            if (!accounts?.length) {
                store.dispatch(redirectToWallet())
                toast.error('Please make sure that your wallet is unlocked and connected to use all features of Marketplace.')
                clearStorageItems()
                return true
            }
            return false
        }
    }
    catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        return true;
    }
}

export const switchNetwork = async (chainId) => {
    const providerInstance = await getProviderFromStorage()
    const provider = await providerInstance?.getProvider()
    let web3Provider = null
    try {
        web3Provider = await new ethers.providers.Web3Provider(provider);
    }
    catch (err) {
        web3Provider = null
    }
    const chainData = await getChainDataByKey('chainId', chainId, blockChains)
    const chainIdHex = chainData?.chainIdHex

    try {
        await web3Provider.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainIdHex }]
        });
        localStorage.setItem('connectedChainId', chainId)
        return true
    }
    catch (switchError) {
        if (switchError?.code === (4902 || -32603)) {
            try {
                await web3Provider.provider.request({
                    method: "wallet_addEthereumChain",
                    params: [ENV.networkParams[chainIdHex]]
                });
                localStorage.setItem('connectedChainId', chainId)
                return true
            }
            catch (error) {
                toast.error(error)
                setWalletError(error);
                return false
            }
        }
        else {
            if (switchError?.message.includes('wallet_switchEthereumChain is not supported'))
                toast.error('Please switch to required network')
            else
                toast.error(switchError.message)
        }
    }
};

export const activateInjectedProvider = async (providerName = 'injected' | 'walletConnect' | 'coinbaseWallet') => {
    const { ethereum } = window;

    if (!ethereum?.providers) {
        return undefined;
    }

    let provider;
    switch (providerName) {
        case 'injected':
            provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
            break;
        case 'walletConnect':
            provider = ethereum.providers.find(({ isWalletLink }) => isWalletLink);
            break;
        case 'coinbaseWallet':
            provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
            break;
    }

    if (provider) {
        ethereum.setSelectedProvider(provider);
    }
}

export const connectWallet = async (showToast = true, activate) => {
    try {
        if (activate) {
            const providerInstance = await getProviderFromStorage()
            const providerFromStorage = localStorage.getItem('provider')
            await activateInjectedProvider(providerFromStorage)
            await activate(providerInstance)
        }

        const web3Provider = await getWeb3Provider()
        if (!web3Provider) {
            if (showToast)
                toast.error("No web3 instance found");
            return false
        }
        else {
            const account = await web3Provider.eth.getAccounts()
            const chainId = await web3Provider.eth.getChainId();

            if (account?.length) {
                const connectedChainId = Number(localStorage.getItem('connectedChainId'))

                if (chainId != connectedChainId) {
                    let chainToSwitch = window.location.pathname === 'login' ? requiredChainIds.includes(chainId) ? chainId : connectedChainId : connectedChainId
                    let isNetworkSwitched = await switchNetwork(chainToSwitch)
                    if (isNetworkSwitched)
                        return account[0]
                    // return false
                }
                else {
                    return account[0]
                }
            }
            else
                return false
        }
    }
    catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage)
        return false;
    }
}

export const signRequest = async () => {
    try {
        if (localStorage.getItem('connectedAddress')) {
            const web3Provider = await getWeb3Provider();
            if (!web3Provider) {
                toast.error("No web3 instance found");
                return false
            }
            const isLocked = await isWalletLocked()
            if (isLocked || isLocked === null) {
                // return false
                return { errMsg: 'Please unlock your wallet and connect again in order to use all features of Marketplace' }
            }
            else {
                const accounts = await web3Provider.eth.getAccounts();
                const chainId = await web3Provider.eth.getChainId();
                const connectedChainId = localStorage.getItem('connectedChainId')

                if (chainId != connectedChainId) {
                    let chainToSwitch = window.location.pathname === 'login' ? requiredChainIds.includes(chainId) ? chainId : connectedChainId : connectedChainId
                    await switchNetwork(chainToSwitch)
                }

                const address = accounts[0];
                if (address && !localStorage.getItem('connectedAddress'))
                    localStorage.setItem('connectedAddress', address);

                const signature = await handleSignMessage(address);
                return signature;
            }

        }
        else {
            return false
        }
    }
    catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }

}

export const mint = async () => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();

        const price = 0;
        const { hash } = await createHash(connectedAddress, price);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        return signature
    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
export const getStakingPercentWeb3 = async (stakePercentData) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const tokenContract = new web3.eth.Contract(
            myntistAbi,
            myntContractAddress
        )

        const stakingPercent = await methods.call(tokenContract.methods.getStakeSharePercent, [stakePercentData.user, stakePercentData.stakeId, stakePercentData.dayToFind])

        return stakingPercent
    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}

//network binance required
export const stakeWeb3 = async (_nftData, _id) => {
    const web3 = getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return false;
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();
        const tokenAddress = _nftData.nft;

        if (_nftData.tokenId) {
            const validOwner = await isValidOwner(connectedAddress, tokenAddress, _nftData.tokenId, _nftData.tokenStandard);
            if (!validOwner) {
                toast.error('Unable to stake the NFT, you don\'t seem to be the owner. Metadata will be refreshed for this NFT');
                let payloadData = {
                    nftId: _id,
                    tokenId: _nftData.tokenId,
                    address: _nftData.nft,
                }
                axiosSyncPost('nfts/update-metadata', payloadData);
                return false;
            }
        }

        const tokenContract = new web3.eth.Contract(
            myntistAbi,
            myntContractAddress,
        );

        const weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))

        const { hash } = await createHash(connectedAddress, weiPrice)
        const stakeSign = await handleSignMessageWithHash(hash, connectedAddress)

        if (stakeSign) {
            const stakeData = {
                newStakedFranks: parseInt(weiPrice),
                newStakedDays: Number(_nftData.stakingDays),
                newStakeName: _nftData.name
            }

            const txDetails = await methods.send(
                tokenContract.methods.stakeStart,
                [stakeData.newStakedFranks, stakeData.newStakedDays, stakeData.newStakeName],
                connectedAddress,
            );

            if (!txDetails.status)
                return false

            const stakeTxHash = txDetails.transactionHash;
            const { stakeId, stakeIndex } = txDetails?.events?.StakeStart?.returnValues

            return { stakeSign, stakeTxHash, stakeId, stakeIndex };
        }

    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
//network binance required
export const unstakeWeb3 = async (stakeIdParam, stakeIndex) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        // toast.error("No web3 instance found");
        return false;
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();
        const tokenContract = new web3.eth.Contract(
            myntistAbi,
            myntContractAddress,
        );

        const { hash } = await createHash(connectedAddress, 0)
        const unstakeSign = await handleSignMessageWithHash(hash, connectedAddress)

        if (unstakeSign) {
            const txDetails = await methods.send(
                tokenContract.methods.stakeEnd,
                [stakeIndex, stakeIdParam],
                connectedAddress,
            );

            if (!txDetails.status)
                return false

            const unstakeTxHash = txDetails.transactionHash;

            return { unstakeSign, unstakeTxHash };
        }

    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
//network with same as nft created
export const cancelSellingWeb3 = async (_nftData, _id) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();
        const tokenAddress = _nftData.nft;

        if (_nftData.tokenId) {
            const validOwner = await isValidOwner(connectedAddress, tokenAddress, _nftData.tokenId, _nftData.tokenStandard);
            if (!validOwner) {
                toast.error("Unable to cancel the listing, you don't seem to be the owner. Metadata will be refreshed for this NFT");
                let payloadData = {
                    nftId: _id,
                    tokenId: _nftData.tokenId,
                    address: _nftData.nft,
                }
                axiosSyncPost('nfts/update-metadata', payloadData);
                return false;
            }
        }

        let weiPrice = web3.utils.toWei(_nftData.price, 'ether');
        if (_nftData.currency?.toUpperCase() === 'MYNT')
            weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))
        const { hash } = await createHash(connectedAddress, weiPrice);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        return signature;
    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
//network with same as nft created, nft contract address as per chain
export const changeSellingStatusWeb3 = async (_nftData, _id, tokenStandard) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const nftContractAddress = await getContractAddress(_nftData.chainId)
        const connectedAddress = await connectWallet();
        const tokenAddress = _nftData.nft;
        let isApprovedForAll = await isApprovedForAllWeb3(connectedAddress, tokenStandard, nftContractAddress, tokenAddress);
        if (!isApprovedForAll) {
            isApprovedForAll = await setApprovalForAllWeb3(connectedAddress, tokenStandard, nftContractAddress, true, tokenAddress);
            if (!isApprovedForAll) {
                toast.error("Unable to complete the listing");
                return false;
            }
        }
        let weiPrice = web3.utils.toWei(String(_nftData.price), 'ether');
        if (_nftData.currency?.toUpperCase() === 'MYNT')
            weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))
        const { hash } = await createHash(connectedAddress, weiPrice);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        return signature;
    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
//network with same as nft created, nft contract address as per chain
export const offerBidWeb3 = async (_nftData) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        // toast.error("No web3 instance found");
        return false;
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const nftContractAddress = await getContractAddress(_nftData.chainId)
        const connectedAddress = await connectWallet();
        // const cabi = JSON.parse(_nftData.cabi);
        const cabi = myntistAbi
        const cAddress = _nftData.cAddress;
        const approvedAmount = await isApproved(nftContractAddress, connectedAddress, cAddress, cabi);
        let weiPrice = web3.utils.toWei(`${_nftData.price}`, 'ether');
        if (_nftData.currency?.toUpperCase() === 'MYNT')
            weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))

        if (parseInt(weiPrice) > parseInt(approvedAmount)) {
            const gotApproval = await getApproval(nftContractAddress, ENV.amountToApprove, _nftData.currency, cAddress, cabi)
            if (!gotApproval) {
                return false;
            }
        }

        const { hash } = await createHash(connectedAddress, weiPrice);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        return signature
    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}

export const cancelOfferBidWeb3 = async () => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        // toast.error("No web3 instance found");
        return false;
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();
        const price = 0;
        const { hash } = await createHash(connectedAddress, price);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        return signature;
    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}

export const acceptOfferBidWeb3 = async (_nftData, buyerAddress, payThrough, _id, collectionId, acceptType, platformSharePercent, royaltySplitPercent) => {
    const web3 = await getWeb3Provider();
    if (!web3) return false;
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();

        // check owner only if NFT is on chain
        if (Number.isInteger(_nftData.tokenId)) {
            const validOwner = await isValidOwner(connectedAddress, _nftData.nft, _nftData.tokenId, _nftData.tokenStandard);

            if (!validOwner) {
                toast.error("Unable to complete the listing, you don't seem to be the owner. Metadata will be refreshed for this NFT");
                let payloadData = {
                    nftId: _id,
                    tokenId: _nftData.tokenId,
                    address: _nftData.nft,
                }
                axiosSyncPost('nfts/update-metadata', payloadData);
                return false;
            }
        }

        const nftContractAddress = await getContractAddress(_nftData.chainId)
        const tokenContract = new web3.eth.Contract(
            contractAbi,
            nftContractAddress,
        );
        let weiPrice = web3.utils.toWei(`${_nftData.price}`, 'ether');
        if (_nftData.currency?.toUpperCase() === 'MYNT')
            weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))

        if (Number(payThrough) === 1)
            weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))

        const weiOwnerShare = percentageOf(weiPrice, 100 - royaltySplitPercent);
        const weiRoyaltyShare = percentageOf(weiPrice, royaltySplitPercent);
        const { hash, nonce, encodeKey } = await createHash(connectedAddress, weiPrice);
        let nftRoyalty = [];
        let platformShareAmount = 0;
        for (let x = 0; x < _nftData.nftRoyalty.length; x++) {
            let royaltyShareVal = percentageOf(weiRoyaltyShare, _nftData.nftRoyalty[x].percent)
            nftRoyalty.push({
                amount: String(royaltyShareVal),
                wallet: _nftData.nftRoyalty[x].wallet
            })
        }
        platformShareAmount = percentageOf(weiPrice, platformSharePercent)
        const signature = await handleSignMessageWithHash(hash, connectedAddress);

        let transferData = {
            acceptType, // 1 = offer, 2 = bid
            metadata: _nftData.metaData || '',
            tokenId: _nftData.tokenId || 0,
            nftId: _nftData.nftId,
            newOwner: buyerAddress,
            nft: _nftData.nft,
            signature,
            payThrough,
            amount: weiPrice,
            percent: _nftData.percent,
            collectionId,
            encodeKey,
            nonce,
            nftRoyalty,
            platformShareAmount: String(platformShareAmount),
            ownerShare: String(weiOwnerShare),
            stakeExists: _nftData.stakeExists,
            stakeIndex: _nftData.stakeIndex
        }


        if (_nftData.tokenStandard === 2) {
            transferData.quantity = _nftData.quantity || 1
            transferData.totalQuantity = _nftData.totalQuantity || 1
        }

        const txDetails = await methods.send(
            tokenContract.methods[_nftData.tokenStandard === 1 ? 'acceptOfferBid721' : 'acceptOfferBid1155'],
            [transferData],
            connectedAddress,
        );
        const txHash = txDetails.transactionHash;
        const { tokenId, stakingIndex } = txDetails?.events?.BidOfferAccepted?.returnValues
        return {
            tokenId: tokenId || 0,
            stakingId: stakingIndex, txHash,
            acceptSign: signature
        };
    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}

// COMMENTED TEMP - OLD FUNCTIONAL CODE FOR ACCEPT OFFER & BID
// export const acceptOfferBidWeb3 = async (_nftData, buyerAddress, payThrough, _id, collectionId, acceptType, platformSharePercent, royaltySplitPercent) => {
//     const web3 = getWeb3();
//     if (!web3) {
//         toast.error("No web3 instance found");
//         return false;
//     }
//     try {
//         const isLocked = await isWalletLocked()
//         if (isLocked)
//             return false

//         const connectedAddress = await connectMetamask(web3);
//         // check owner only if NFT is on chain
//         if (Number.isInteger(_nftData.tokenId)) {
//             const validOwner = await isValidOwner(connectedAddress, _nftData.nft, _nftData.tokenId);
//             if (!validOwner) {
//                 toast.error("Unable to complete the listing, you don't seem to be the owner. Metadata will be refreshed for this NFT");
//                 let payloadData = {
//                     nftId: _id,
//                     tokenId: _nftData.tokenId,
//                     address: _nftData.nft,
//                 }
//                 axiosSyncPost('nfts/update-metadata', payloadData);
//                 return false;
//             }
//         }
//         const tokenContract = new web3.eth.Contract(
//             contractAbi,
//             nftContractAddress,
//         );
//         let weiPrice = web3.utils.toWei(`${_nftData.price}`, 'ether');
//         if (Number(payThrough) === 1)
//             weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))

//         const weiOwnerShare = percentageOf(weiPrice, 100 - royaltySplitPercent);
//         const weiRoyaltyShare = percentageOf(weiPrice, royaltySplitPercent);
//         const { hash, nonce, encodeKey } = await createHash(connectedAddress, weiPrice);
//         let nftRoyalty = [];
//         let platformShareAmount = 0;
//         for (let x = 0; x < _nftData.nftRoyalty.length; x++) {
//             let royaltyShareVal = percentageOf(weiRoyaltyShare, _nftData.nftRoyalty[x].percent)
//             nftRoyalty.push({
//                 amount: String(royaltyShareVal),
//                 wallet: _nftData.nftRoyalty[x].wallet
//             })
//         }
//         platformShareAmount = percentageOf(weiPrice, platformSharePercent)
//         const signature = await handleSignMessageWithHash(hash, connectedAddress);
//         const transferData = {
//             acceptType, // 1 = offer, 2 = bid
//             metadata: _nftData.metaData || '',
//             tokenId: _nftData.tokenId || 0,
//             nftId: _nftData.nftId,
//             newOwner: buyerAddress,
//             nft: _nftData.nft,
//             signature,
//             payThrough,
//             amount: weiPrice,
//             percent: _nftData.percent,
//             collectionId,
//             encodeKey,
//             nonce,
//             nftRoyalty,
//             platformShareAmount: String(platformShareAmount),
//             ownerShare: String(weiOwnerShare)
//         }
//         const txDetails = await methods.send(
//             tokenContract.methods.acceptOfferBid,
//             [transferData],
//             connectedAddress,
//         );
//         const txHash = txDetails.transactionHash;
//         const { tokenId } = txDetails?.events?.BidOfferAccepted?.returnValues
//         return {
//             tokenId, txHash,
//             acceptSign: signature
//         };
//     } catch (e) {
//         const eMessage = e.message.split('{')[0] || '';
//         toast.error(eMessage);
//         return false;
//     }
// }

export const buyNow = async (_nftData, payThrough, royaltySplitPercent, /* cabi */ cAddress, tokenStandard) => {
    const web3 = await getWeb3Provider();
    if (!web3) return false;
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();
        const nftContractAddress = await getContractAddress(_nftData.chainId)
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);
        const cabi = myntistAbi
        const tokenAddress = _nftData.nft

        if (_nftData.tokenId) {
            const validOwner = await isValidOwner(_nftData.owner, tokenAddress, _nftData.tokenId, tokenStandard);
            if (!validOwner) {
                toast.error("Unable to Buy NFT, seller doesn't seem to be the owner. Metadata will be refreshed for this NFT");
                let payloadData = {
                    nftId: _nftData._id,
                    tokenId: _nftData.tokenId,
                    address: tokenAddress,
                }
                axiosSyncPost('nfts/update-metadata', payloadData);
                return false;
            }
        }

        let weiPrice = web3.utils.toWei(`${_nftData.price}`, 'ether');
        if (_nftData.currency?.toUpperCase() === 'MYNT')
            weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))

        if (cabi && cAddress && payThrough !== 2) {
            const approvedAmount = await isApproved(nftContractAddress, connectedAddress, cAddress, cabi);

            if (parseInt(weiPrice) > parseInt(approvedAmount)) {
                const gotApproval = await getApproval(nftContractAddress, ENV.amountToApprove, _nftData.currency, cAddress, cabi)
                if (!gotApproval) {
                    return false;
                }
            }
        }

        const weiOwnerShare = percentageOf(weiPrice, 100 - royaltySplitPercent);
        const weiRoyaltyShare = percentageOf(weiPrice, royaltySplitPercent);
        const { hash, nonce, encodeKey } = await createHash(connectedAddress, weiPrice);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);

        delete _nftData.price;

        // currency values: 0 = Buy with PayPal, 1 = Buy Now With BNB / Buy Now With ETH, 2 = Buy Now With MYNT
        _nftData.currency = payThrough === 1 ? (_nftData.currency.toUpperCase() === 'BNB' || _nftData.currency.toUpperCase() === 'ETH' ? 1 : _nftData.currency.toUpperCase() === 'MYNT' ? 2 : 0) : 0

        let nftRoyalty = [];
        for (let x = 0; x < _nftData.nftRoyalty.length; x++) {
            let royaltyShareVal = percentageOf(weiRoyaltyShare, _nftData.nftRoyalty[x].percent)
            nftRoyalty.push({
                amount: String(royaltyShareVal),
                wallet: _nftData.nftRoyalty[x].wallet
            })
        }
        let buyNFTData = {
            ..._nftData,
            signature, payThrough, amount: weiPrice, encodeKey, nonce, nftRoyalty, ownerShare: String(weiOwnerShare),
        }

        delete buyNFTData._id
        delete buyNFTData.nftId
        delete buyNFTData.chainId

        if (payThrough === 2) {
            let encodedData = null

            if (tokenStandard === 1)
                encodedData = await tokenContract.methods.buy721(buyNFTData).encodeABI()
            if (tokenStandard === 2)
                encodedData = await tokenContract.methods.buy1155(buyNFTData).encodeABI()

            const estimatedGasLimit = await web3.eth.estimateGas({
                from: connectedAddress,
                nonce: Date.now(),
                to: nftContractAddress,
                data: encodedData,
            })

            const weiGasLimit = await web3.utils.toWei(`${estimatedGasLimit}`, 'Gwei')
            const gasLimitInEth = await web3.utils.fromWei(`${weiGasLimit}`, 'ether')
            return { gasLimitInEth, signature }
        }

        // call method either buy721 or buy1155
        const txDetails = await methods.send(
            tokenContract.methods[tokenStandard === 1 ? 'buy721' : 'buy1155'],
            [buyNFTData],
            connectedAddress,
            payThrough === 1 && buyNFTData.currency === 1 ? buyNFTData.amount : 0 // value
        );

        if (!txDetails.status)
            return false;

        const txHash = txDetails.transactionHash;
        const { tokenId, stakingIndex } = txDetails.events?.[tokenStandard === 1 ? 'Nft721Transferred' : 'Nft1155Transferred']?.returnValues
        return {
            txHash, signature,
            tokenId: tokenId || 0,
            stakingId: stakingIndex
        };
    } catch (e) {
        let eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
// COMMENTED TEMP. - old functional method for DIRECT BUY feature
// export const buyNow = async (_nftData, payThrough, royaltySplitPercent, cabi, cAddress) => {
//     const web3 = await getWeb3();
//     if (!web3) {
//         toast.error("No web3 instance found");
//         return false;
//     }
//     try {
//         const isLocked = await isWalletLocked()
//         if (isLocked)
//             return false

//         const connectedAddress = await connectMetamask(web3);
//         const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);

//         let weiPrice = web3.utils.toWei(`${_nftData.price}`, 'ether');
//         if (_nftData.currency?.toUpperCase() === 'MYNT')
//             weiPrice = String((parseFloat((_nftData.price * ENV.myntMaxDecimals).toFixed(10))))

//         if (cabi && cAddress) {
//             const approvedAmount = await isApproved(nftContractAddress, connectedAddress, cAddress, cabi);

//             if (parseInt(weiPrice) > parseInt(approvedAmount)) {
//                 const gotApproval = await getApproval(nftContractAddress, ENV.amountToApprove, _nftData.currency, cAddress, cabi)
//                 if (!gotApproval) {
//                     return false;
//                 }
//             }
//         }

//         const weiOwnerShare = percentageOf(weiPrice, 100 - royaltySplitPercent);
//         const weiRoyaltyShare = percentageOf(weiPrice, royaltySplitPercent);
//         const { hash, nonce, encodeKey } = await createHash(connectedAddress, weiPrice);
//         const signature = await handleSignMessageWithHash(hash, connectedAddress);
//         delete _nftData.price;

//         // currency values: 0 = Buy with PayPal, 1 = Buy Now With BNB, 2 = Buy Now With MYNT
//         _nftData.currency = payThrough === 1 ? (_nftData.currency.toUpperCase() === 'BNB' ? 1 : _nftData.currency.toUpperCase() === 'MYNT' ? 2 : 0) : 0

//         let nftRoyalty = [];
//         for (let x = 0; x < _nftData.nftRoyalty.length; x++) {
//             let royaltyShareVal = percentageOf(weiRoyaltyShare, _nftData.nftRoyalty[x].percent)
//             nftRoyalty.push({
//                 amount: String(royaltyShareVal),
//                 wallet: _nftData.nftRoyalty[x].wallet
//             })
//         }
//         const buyNFTData = {
//             ..._nftData,
//             signature, payThrough, amount: weiPrice, encodeKey, nonce, nftRoyalty, ownerShare: String(weiOwnerShare)
//         }

//         const txDetails = await methods.send(
//             tokenContract.methods.buyNFT,
//             [buyNFTData],
//             connectedAddress,
//             payThrough === 1 && buyNFTData.currency === 1 ? buyNFTData.amount : 0 // value
//         );

//         if (!txDetails.status)
//             return false;

//         const txHash = txDetails.transactionHash;
//         return {
//             txHash, signature
//         };
//     } catch (e) {
//         let eMessage = e.message.split('{')[0] || '';
//         toast.error(eMessage);
//         return false;
//     }
// }

export const getApproval = async (guy, amount, currency, contractAddress, contractABI) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const connectedAddress = await connectWallet();
        const tokenContract = new web3.eth.Contract(
            contractABI,
            contractAddress,
        );
        let weiPrice = web3.utils.toWei(`${amount}`, 'ether');
        if (currency?.toUpperCase() === 'MYNT')
            weiPrice = String((parseFloat((amount * ENV.myntMaxDecimals).toFixed(10))))
        await methods.send(
            tokenContract.methods.approve,
            [guy, weiPrice],
            connectedAddress,
        );

        return true;
    } catch (e) {
        let eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
export const isApproved = async (guy, connectedAddress, contractAddress, contractABI) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const tokenContract = new web3.eth.Contract(
            contractABI,
            contractAddress,
        );
        const myNewData = await methods.call(tokenContract.methods.allowance, [connectedAddress, guy])

        return myNewData;
    } catch (e) {
        return 0;
    }
}
//network as of nft
export const checkBalance = async (_nftData) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();
        // const cabi = JSON.parse(_nftData.cabi);
        const cabi = myntistAbi
        const cAddress = _nftData.cAddress;
        const tokenContract = new web3.eth.Contract(
            cabi,
            cAddress,
        );
        let userBalance = await methods.call(tokenContract.methods.balanceOf, [connectedAddress])
        if (userBalance)
            if (_nftData.currency?.toUpperCase() === 'MYNT')
                userBalance = parseFloat((userBalance / ENV.myntMaxDecimals).toFixed(10))
            else
                userBalance = web3.utils.fromWei(userBalance, 'ether')
        return userBalance;
    } catch (e) {
        toast.error("Error while checking balance");
        return 0;
    }
}
//network as of nft, need to update for 1155
export const isValidOwner = async (owner, contractAddress, tokenId, tokenStandard) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const contractAbi = tokenStandard === 1 ? myntist721Abi : myntist1155Abi
        const tokenContract = new web3.eth.Contract(
            contractAbi,
            contractAddress,
        );

        // check number of copies against nft
        if (tokenStandard === 2) {
            let nftCopies = await methods.call(tokenContract.methods.balanceOf, [owner, tokenId])
            return nftCopies
        }

        const nftOwner = await methods.call(tokenContract.methods.ownerOf, [tokenId])
        return nftOwner === owner;
    } catch (e) {
        return false;
    }
}
//network as of nft
export const isApprovedForAllWeb3 = async (owner, tokenStandard, operator, contractAddress) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const tokenContract = new web3.eth.Contract(
            tokenStandard === 1 ? myntist721Abi : myntist1155Abi,
            contractAddress,
        );
        const isApprovedForAll = await methods.call(tokenContract.methods.isApprovedForAll, [owner, operator])

        return isApprovedForAll;
    } catch (e) {
        return 0;
    }
}
//network as of nft
export const setApprovalForAllWeb3 = async (connectedAddress, tokenStandard, operator, value, contractAddress) => {
    const web3 = await getWeb3Provider();
    if (!web3) {
        toast.error("No web3 instance found");
        return false;
    }
    try {
        const tokenContract = new web3.eth.Contract(
            tokenStandard === 1 ? myntist721Abi : myntist1155Abi,
            contractAddress,
        );
        await methods.send(
            tokenContract.methods.setApprovalForAll,
            [operator, value],
            connectedAddress,
        );

        return true;
    } catch (e) {
        return 0;
    }
}
const percentageOf = (num, per) => {
    return parseFloat(((num / 100) * per).toFixed(10));
}
const handleSignMessage = async (address) => {
    if (!address) return

    const web3 = await getWeb3Provider();
    const signature = await web3.eth.personal.sign(
        web3.utils.fromUtf8(`${ENV.appName} uses this cryptographic signature in place of a password, verifying that you are the owner of this address.`),
        address,
        (err, signature) => {
            if (err) return err;
            return signature;
        }
    )
    return signature
};

const handleSignMessageWithHash = async (hash, wallet) => {
    if (!hash || !wallet)
        return false

    const web3 = await getWeb3Provider();
    const signature = await web3.eth.personal.sign(
        hash,
        wallet,
        (err, signature) => {
            if (err) return err;
            return signature;
        }
    )

    return signature;
}
const createHash = async (wallet, _amount) => {
    const encodeKey = getEncodeKey();
    const nonce = Date.now();
    const web3 = await getWeb3Provider();
    const hash = await web3.utils.soliditySha3(wallet, _amount, encodeKey, nonce);
    return { hash, nonce, encodeKey };
}
const getEncodeKey = () => {
    return randomstring.generate({
        length: 20,
        charset: 'alphabetic'
    });
}


export const estimateGasFee = async (data) => {
    try {
        const web3 = await getWeb3Provider();
        if (!web3) {
            return false;
        }

        const isLocked = await isWalletLocked()
        if (isLocked || isLocked === null)
            return false

        const connectedAddress = await connectWallet();
        const chainData = await getChainDataByKey('chainId', data.chainId, blockChains)
        const nftContractAddress = chainData.contractAddress

        if (connectedAddress) {

            // estimate gas fee 
            const estimatedGasFee = await web3.eth.estimateGas({
                from: connectedAddress,
                nonce: Date.now(),
                to: nftContractAddress,
                // data: acceptData,
            })

            const weiGasPrice = await web3.utils.toWei(estimatedGasFee, 'gwei')
            const etherGasPrice = await web3.utils.fromWei(weiGasPrice, 'ether')

            return etherGasPrice
        }
        return false
    }
    catch (err) {
        return false
    }
}

export const accountsChangedHandler = async () => {
    if (localStorage.getItem('provider')) {
        // const connector = await connectors[providerFromStorage]
        const connector = await getProviderFromStorage()
        const providerInstance = await connector?.getProvider()
        if (providerInstance) {

            providerInstance?.on('chainChanged', (payload) => {
                chainChangedHandler(payload, connector)
            })
            providerInstance.on("network", (newNetwork, oldNetwork) => {
                // console.log('Old and New network', oldNetwork, newNetwork)
            });
        }

        connector.on('Web3ReactUpdate', async function (payload) {

            if (payload.chainId)
                chainChangedHandler(payload.chainId, payload.provider || connector)

            if (payload.account)
                accountChangedhandler(payload.account)
        })

    }
}

const clearStorageItems = () => {
    localStorage.removeItem('connectedAddress')
    localStorage.removeItem('encuse')
}

const convertChainId = async (chainId, provider) => {
    if (!provider)
        return false

    const web3Provider = await new Web3(provider);
    let chainIdDecimal = chainId

    if (web3Provider.utils.isHexStrict(chainId))
        chainIdDecimal = web3Provider.utils.hexToNumber(chainId)
    else
        chainIdDecimal = Number(chainIdDecimal)

    return chainIdDecimal
}

const chainChangedHandler = async (chainId, provider) => {

    const chainIdDecimal = await convertChainId(chainId, provider)
    const connectedChainId = Number(localStorage.getItem('connectedChainId'))

    if (chainIdDecimal) {
        if (connectedChainId && connectedChainId === chainIdDecimal) { }

        // if user switches to other network from wallet which is supported 
        else if (chainIdDecimal !== connectedChainId &&
            requiredChainIds.includes(chainIdDecimal)) {

            // switchNetwork(chainIdDecimal)
            localStorage.setItem('connectedChainId', chainIdDecimal)
            store.dispatch(redirectToWallet())

        }
        else {
            toast.error('Network Not supported!', { toastId: 'chain-not-supported' })
            // switchNetwork(connectedChainId)
            store.dispatch(redirectToWallet())
            clearStorageItems()
        }
    }
}

const accountChangedhandler = async (account) => {

    let connectedAddress = localStorage.getItem('connectedAddress')
    if (connectedAddress && connectedAddress !== account) {
        store.dispatch(redirectToWallet())
        clearStorageItems()
    }
}

const setWalletError = (message) => {
    return {
        type: SET_WALLET_ERROR,
        payload: message
    }
}
// redirect user to connect wallet
const redirectToWallet = () => {
    return {
        type: REDIRECT_TO_WALLET,
        payload: true
    }
}

const getContractAddress = (chainId) => {

    let blockchains = [...blockChains]
    let contractAddress = blockchains.filter((b) => b.chainId === chainId)[0]?.contractAddress
    return contractAddress
}

accountsChangedHandler();
