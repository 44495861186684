import { VIEW_FEEDBACKS, BEFORE_FEEDBACKS, ADD_FEEDBACK, GET_FEEDBACK } from '../../redux/types';

const initialState = {

    feedbacksList: null,
    feedbacksListAuth: false,

    addFeedbackRes: null,
    addFeedbackAuth: false,

    getFeedbackRes: null,
    getFeedbackAuth: false
}

export default function FeedbacksRed(state = initialState, action) {
    switch (action.type) {
        case VIEW_FEEDBACKS:
            return {
                ...state,
                feedbacksList: action.payload,
                feedbacksListAuth: true
            }
        case ADD_FEEDBACK:
            return {
                ...state,
                addFeedbackRes: action.payload,
                addFeedbackAuth: true
            }
        case GET_FEEDBACK:
            return {
                ...state,
                getFeedbackRes: action.payload,
                getFeedbackAuth: true
            }
        case BEFORE_FEEDBACKS:
            return {
                ...state,
                feedbacksList: null,
                feedbacksListAuth: false,

                addFeedbackRes: null,
                addFeedbackAuth: false,

                getFeedbackRes: null,
                getFeedbackAuth: false
            }
        default:
            return {
                ...state
            }
    }
}