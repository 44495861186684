import { ENV } from '../config/config'
const { cdnBaseUrl } = ENV
const siteLogo = `${cdnBaseUrl}v1652166289/hex-nft/assets/logo_js4k0h.svg`;
const siteLogoM = `${cdnBaseUrl}v1676627411/hex-nft/assets/images/mynist_s1vmud_fnlrox.png`; //
const iconSearch = `${cdnBaseUrl}v1652166671/hex-nft/assets/icon-search_b7dy65.svg`;
const bannerImg = `${cdnBaseUrl}v1676627422/hex-nft/assets/images/bg-banner-img_gpjreo_yjw4wz.png`;  //
const gamingIcon = `${cdnBaseUrl}v1676627398/hex-nft/assets/images/gaming-logo_ef60f5_ui4lxs.svg`; //
const goldenBg  = `${cdnBaseUrl}v1676627399/hex-nft/assets/images/golden-bg-2_nixwcf_ovb3qu.png`;   // 
const myntistLogo  = `${cdnBaseUrl}v1676627411/hex-nft/assets/images/myntist-logo_fnnuqb_xmuuuu.png`; // 
const blueSearch  = `${cdnBaseUrl}v1676627425/hex-nft/assets/images/blue-search_jqi56l_kbqtbi.png`;  //
const cyanSearch  = `${cdnBaseUrl}v1676627433/hex-nft/assets/images/cyan-search_g1bcta_b1bjb2.png`;  //
const whiteSearch  = `${cdnBaseUrl}v1676627405/hex-nft/assets/images/white-search_jbj4tt_jkekex.png`; //
const blurDarkSearch  = `${cdnBaseUrl}v1676627425/hex-nft/assets/images/blur-dark-search_osigah_jbxdkg.png`; //
const uploadIcon  = `${cdnBaseUrl}v1676627395/hex-nft/assets/images/upload-icon_qvj33i_iskyuh.png`; //
const imageOne = `${cdnBaseUrl}v1676627399/hex-nft/assets/images/Group-52_ytb5dt_slduub.svg`; //
const imageTwo = `${cdnBaseUrl}v1676627399/hex-nft/assets/images/Group-53_zfxkf5_juntas.svg`; //
const imageThree = `${cdnBaseUrl}v1676627400/hex-nft/assets/images/Group-54_bailjh_e6swct.svg`; //
const imageFour = `${cdnBaseUrl}v1676627401/hex-nft/assets/images/Group-55_ceewkk_qkiwsh.svg`; //
const imageFive = `${cdnBaseUrl}v1676627401/hex-nft/assets/images/Group-56_vti2af_m18xdi.svg`; //
const imageSix = `${cdnBaseUrl}v1676627409/hex-nft/assets/images/account_n84ugw_w6xh0j.svg`; //
const imageSeven = `${cdnBaseUrl}v1676627397/hex-nft/assets/images/wallet_x3div9_nmsepi.svg`; //
const imageEight = `${cdnBaseUrl}v1676627433/hex-nft/assets/images/csv_cjlrmg_h89mwj.svg`; //
const imageNine = `${cdnBaseUrl}v1676627396/hex-nft/assets/images/fill_bud4dp_bpq0pz.svg`; //
const imageTen = `${cdnBaseUrl}v1676627417/hex-nft/assets/images/upload_t5vg4v_ckpcoc.svg`; //
const ethIcon = `${cdnBaseUrl}v1676627402/hex-nft/assets/images/Icon-ethereum_paddbm_z5nuim.png`; //
const iconCreateWallet = `${cdnBaseUrl}v1676627398/hex-nft/assets/images/wallet_fp7rnb_wjqxx8.png`; //
const iconAddNft = `${cdnBaseUrl}v1676627410/hex-nft/assets/images/add-nft_vz0qos_qvp3ue.png`; //
const iconCreateNft = `${cdnBaseUrl}v1676627413/hex-nft/assets/images/sell-nft_k3qqrn_jdrqtr.png`; //
const statusIcon = `${cdnBaseUrl}v1676627416/hex-nft/assets/images/status_hzeqac_bhdcl1.png`; //
const statusIconNew = `${cdnBaseUrl}v1676627416/hex-nft/assets/images/status-new_f1ms9s_szdy5b.png`; //
const priceIcon = `${cdnBaseUrl}v1676627413/hex-nft/assets/images/price_cgmyi5_ezxwpp.png`; //
const priceIconNew = `${cdnBaseUrl}v1676627413/hex-nft/assets/images/price-new_iplqqa_smjn3r.png`; //
const collectionIcon = `${cdnBaseUrl}v1676627429/hex-nft/assets/images/collection_ydfnbh_iavg9p.png`; //
const collectionIconNew = `${cdnBaseUrl}v1676627429/hex-nft/assets/images/collection-new_kwcbmf_qjrnru.png`; //
const categoryIcon = `${cdnBaseUrl}v1676627427/hex-nft/assets/images/category_c8legh_vmbih0.png`;  //
const categoryIconNew = `${cdnBaseUrl}v1676627428/hex-nft/assets/images/category-new_zkoypd_g0tozc.png`; //
const authorIcon = `${cdnBaseUrl}v1676627412/hex-nft/assets/images/author_uvzrse_jmhu7a.png`;  //
const authorIconNew = `${cdnBaseUrl}v1676627415/hex-nft/assets/images/author-new_mv838r_c57mmg.png`; //
const favIcon = `${cdnBaseUrl}v1655727474/hex-nft/assets/mynist_s1vmud.png`; // 
const eOne = `${cdnBaseUrl}v1676627414/hex-nft/assets/images/shopping-cart-svgrepo-com_incwlw_gyergp.svg`;  //
const eTwo = `${cdnBaseUrl}v1676627412/hex-nft/assets/images/online-shop-ecommerce-svgrepo-com_ehfib5_avbkte.svg`; //
const eThree = `${cdnBaseUrl}v1676627414/hex-nft/assets/images/shopping-online-svgrepo-com_lodwvy_nzftza.svg`; //
const allIcon = `${cdnBaseUrl}v1676627397/hex-nft/assets/images/fr-banner-bg_txi4ox_teluxg.png`; //
const giftCard = `${cdnBaseUrl}v1676627412/hex-nft/assets/images/OBJECTS_w5nef8_sjkjcy.svg`; //
const scanBlack = `${cdnBaseUrl}v1676627395/hex-nft/assets/images/etherscan-black_j2hyck_vunnpt.png`; //
const scanCyan = `${cdnBaseUrl}v1676627395/hex-nft/assets/images/etherscan-cyan_ftj9nz_a0cgdu.png`; //

export const Images = {  
	scanBlack,
	scanCyan,
	siteLogo,
	iconSearch,
	bannerImg,
	iconCreateWallet,
	iconAddNft,
	iconCreateNft,
	gamingIcon,
	goldenBg,
	myntistLogo,
	blueSearch,
	whiteSearch,
	uploadIcon,
	imageOne,
	imageTwo,
	imageThree,
	imageFour,
	imageFive,
	imageSix,
	imageSeven,
	imageEight,
	imageNine,
	imageTen,
	ethIcon,
	statusIcon,
	statusIconNew,
	priceIcon,
	priceIconNew,
	collectionIcon,
	collectionIconNew,
	categoryIcon,
	categoryIconNew,
	authorIcon,
	authorIconNew,
	siteLogoM,
	cyanSearch,
	favIcon,
	eOne,
	eTwo,
	eThree,
	allIcon,
	blurDarkSearch,
	giftCard
}