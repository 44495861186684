import { BULK_UPLOAD_NFTS, BEFORE_BULK_UPLOAD, BULK_UPLOAD_NFTS_LOGS } from '../../../redux/types';

const initialState = {
    bulkUploadNftsRes: null,
    bulkUploadNftsAuth: false,

    bulkUploadLogs: null,
    bulkUploadLogsAuth: false
}

export default function bulkUploadReducer(state = initialState, action) {
    switch (action.type) {
        case BULK_UPLOAD_NFTS:
            return {
                ...state,
                bulkUploadNftsRes: action.payload,
                bulkUploadNftsAuth: true
            }
        case BULK_UPLOAD_NFTS_LOGS:
            return {
                ...state,
                bulkUploadLogs: action.payload,
                bulkUploadLogsAuth: true
            }
        case BEFORE_BULK_UPLOAD:
            return {
                ...state,
                bulkUploadNftsRes: null,
                bulkUploadNftsAuth: false,

                bulkUploadLogs: null,
                bulkUploadLogsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}