import { GET_CONTENT_PAGES, GET_CONTENT_PAGE, BEFORE_CONTENT, BEFORE_CONTENT_PAGES } from '../../redux/types';

const initialState = {
    contentPages: {},
    contentPageRes: {},
    contentPageResAuth: false,
    contentPagesResAuth: false

}

export default function CmsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CONTENT_PAGES:
            return {
                ...state,
                contentPages: action.payload,
                contentPagesResAuth: true
            }
        case GET_CONTENT_PAGE:
            return {
                ...state,
                contentPageRes: action.payload,
                contentPageResAuth: true
            }
        case BEFORE_CONTENT:
            return {
                ...state,
                contentPageRes : {},
                contentPageResAuth: false,
            }
        case BEFORE_CONTENT_PAGES:
            return {
                ...state,
                contentPages: {},
                contentPagesResAuth: false
            }
        default:
            return {
                ...state
            }
    }
}