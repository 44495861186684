import { SET_COUNTER, BEFORE_COUNTER, USER_NOTIFICATIONS, BEFORE_NOTIFICATIONS, EDIT_NOTIFICATION_STATUS, BEFORE_NOTIFICATION_STATUS } from '../../redux/types';

const initialState = {
    notificationsCounter: Number(localStorage.getItem('notificationsCounter')) || null,
    notificationsCounterAuth: false,

    userNotifications: null,
    userNotificationsAuth: false,

    editNotificationsStatus: null,
    editNotificationsStatusAuth: false
}

export default function notificationsRed(state = initialState, action) {
    switch (action.type) {

        case SET_COUNTER:
            return {
                ...state,
                notificationsCounter: state.notificationsCounter + 1,
                notificationsCounterAuth: true
            }
        case BEFORE_COUNTER:
            return {
                ...state,
                notificationsCounter: null,
                notificationsCounterAuth: false
            }
        case USER_NOTIFICATIONS:
            return {
                ...state,
                userNotifications: action.payload,
                userNotificationsAuth: true
            }
        case BEFORE_NOTIFICATIONS:
            return {
                ...state,
                userNotifications: null,
                userNotificationsAuth: false
            }
        case EDIT_NOTIFICATION_STATUS:
            return {
                ...state,
                editNotificationsStatus: action.payload,
                editNotificationsStatusAuth: true
            }
        case BEFORE_NOTIFICATION_STATUS:
            return {
                ...state,
                editNotificationsStatus: null,
                editNotificationsStatusAuth: false
            }
        default:
            return {
                ...state
            }
    }
}